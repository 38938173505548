<template>
  <el-container class="mx-3">
    <el-row justify="center" class="w-100">
      <el-col :span="24">
        <el-card>
          <template #header>
            <div class="font-weight-light">Complete your profile</div>
          </template>
          <el-tabs success v-model="activeTab">
            <!-- Personal Info Form  -->
            <el-tab-pane label="Personal Info" name="personalInfo">
              <span slot="label"
                ><i class="el-icon-user"></i> Personal Info</span
              >
              <el-form
                ref="personalInfoFormRef"
                :model="user"
                :rules="personalInfoRules"
                label-position="top"
                @submit.prevent="save"
              >
                <el-row class="pt-2" :gutter="20">
                  <!-- First Name  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="First Name"
                      label-width="20%"
                      prop="first_name"
                    >
                      <el-input
                        placeholder="First Name"
                        v-model="user.first_name"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Last Name  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Last Name"
                      label-width="20%"
                      prop="last_name"
                    >
                      <el-input
                        v-model="user.last_name"
                        class="purple-input"
                        placeholder="Last Name"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Email  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item label="Email" label-width="20%" prop="email">
                      <el-input
                        style="width: 100%"
                        placeholder="Email"
                        v-model="user.email"
                        class="purple-input"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Age  -->
                  <el-col :sm="6" class="my-2">
                    <el-form-item
                      label="Age"
                      label-width="20%"
                      prop="age_group_id"
                    >
                      <el-select
                        v-model="user.age_group_id"
                        placeholder=" Age"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in ageList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- Language  -->
                  <el-col :sm="6" class="my-2">
                    <el-form-item
                      label="Language"
                      label-width="20%"
                      prop="language"
                    >
                      <el-select
                        v-model="user.language"
                        placeholder=" Language"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in $store.getters['language/languages']"
                          :value="item.id"
                          :label="item.title"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- Postal Code  -->
                  <el-col :sm="12" class="my-2">
                    <!-- <el-form-item
                    label="ZIP / Postal Code"
                    label-width="20%"
                    prop="zip_code"
                  >
                    <el-input
                      v-model="user.zip_code"
                      placeholder="ZIP / Postal Code"
                    ></el-input>
                  </el-form-item> -->
                    <el-form-item
                      label="ZIP / Postal Code"
                      label-width="20%"
                      prop="zip_code"
                    >
                      <el-input
                        v-model="user.zip_code"
                        placeholder="ZIP / Postal Code"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- Country  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Country"
                      label-width="20%"
                      prop="country"
                    >
                      <el-select
                        v-model="user.country"
                        filterable
                        placeholder=" Country"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in countries"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col class="text-right">
                    <l-button @click="save" type="info" class="mr-0">
                      Change My Info
                    </l-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <!-- Google Classroom Form  -->
            <el-tab-pane
              label="Google Class"
              name="googleClass"
              v-loading="classroomLoading"
            >
              <span slot="label"
                ><i class="el-icon-users"></i> Google Classroom</span
              >

              <el-form
                ref="googleClassFormRef"
                :model="syncForm"
                :rules="googleClassRules"
                label-position="top"
              >
                <el-row class="pt-2" :gutter="20">
                  <!-- &lt;!&ndash; Email &ndash;&gt; -->
                  <el-col :lg="12">
                    <el-form-item label="Email" label-width="20%" prop="email">
                      <el-input
                        style="width: 100%"
                        placeholder="Email"
                        v-model="syncForm.email"
                        class="purple-input"
                        :disabled="this.user.student_classroom_id !== null"
                      />
                    </el-form-item>
                  </el-col>

                  <el-col class="text-right">
                    <l-button
                      v-if="!this.user.student_classroom_id"
                      @click="syncClass()"
                      type="info"
                      class="mr-0"
                    >
                      Sync Class
                    </l-button>
                    <l-button
                      v-else
                      @click="unsyncClass()"
                      type="danger"
                      class="mr-0"
                    >
                      Unsync class
                    </l-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <!-- Institution Info Form  -->
            <el-tab-pane
              name="institutionInfo"
              label="Institution Info"
              v-loading="isInstitutionInfoLoading"
            >
              <span slot="label"
                ><i class="el-icon-office-building"></i> Institution Info</span
              >
              <el-card>
                <template #header>
                  <div class="mb-2">My Institution</div>
                </template>
                <span class="d-block mb-2"
                  ><span>Institution Name:</span>
                  {{ user.organization_name }}</span
                >
                <span class="d-block mb-2"
                  ><span>Institution Email:</span>
                  {{ user.organization_email }}</span
                >
                <span class="d-block mb-2">
                  <span>Institution Contact:</span>
                  {{ user.organization_contact_name }}</span
                >
                <el-col :span="24" class="text-right">
                  <el-button
                    v-if="!user.organization_id"
                    @click="show_institution_promo_dialog = true"
                    type="success"
                    class="mb-3"
                  >
                    Join Institution
                  </el-button>
                  <el-button
                    v-if="user.organization_id"
                    @click="leaveOrganization"
                    type="danger"
                    class="mb-3"
                  >
                    Leave Institution
                  </el-button>
                </el-col>
              </el-card>
              <!-- Join new Institution Form  -->
              <el-dialog
                title="Enter Institution Promo Code"
                :visible.sync="show_institution_promo_dialog"
                width="70%"
                append-to-body
              >
                <el-form
                  :model="promoForm"
                  v-loading="isInstitutionInfoLoading"
                  :rules="promoRules"
                  ref="promoRef"
                  @submit.prevent="submitPromoCode"
                >
                  <el-form-item label="Promo Code" prop="new_institution_promo">
                    <el-input
                      v-model="promoForm.new_institution_promo.promo_code"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <span
                  slot="footer"
                  class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 ml-auto"
                >
                  <el-button
                    @click="show_institution_promo_dialog = false"
                    class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
                    >Cancel</el-button
                  >
                  <el-button
                    type="primary"
                    @click="submitPromoCode"
                    class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
                    >Confirm</el-button
                  >
                </span>
              </el-dialog>
            </el-tab-pane>
            <!-- Reset Password Form  -->
            <el-tab-pane
              name="resetPassword"
              label="Reset Password"
              v-loading="isResetPasswordLoading"
            >
              <span slot="label"
                ><i class="el-icon-lock"></i> Change Password</span
              >
              <el-card>
                <template #header>
                  <div class="mb-2">Change User Password</div>
                </template>
                <el-form
                  v-model="resetPassword"
                  :model="resetPassword"
                  ref="resetPassRef"
                  :rules="resetPassRules"
                >
                  <div>
                    <el-row :gutter="20">
                      <el-col :span="24" :lg="12">
                        <el-form-item
                          label="Password"
                          prop="password"
                          style="width: 100%"
                          class="position-relative"
                        >
                          <!-- Show Password Icon  -->
                          <i
                            v-if="passwordType == 'password'"
                            class="fa fa-eye position-absolute togglePassword text-secondary"
                            @click="toggleShowPassword"
                            v-tooltip.top-center="'Show Password'"
                            aria-hidden="true"
                          ></i>
                          <i
                            v-if="passwordType == 'text'"
                            class="fa fa-eye-slash position-absolute togglePassword text-secondary"
                            @click="toggleShowPassword"
                            v-tooltip.top-center="'Hide Password'"
                            aria-hidden="true"
                          ></i>
                          <el-input
                            :type="passwordType"
                            v-model="resetPassword.password"
                            placeholder="Please enter Password"
                            style="width: 100%"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :lg="12">
                        <el-form-item
                          label="Confirm Password"
                          prop="confirmPassword"
                          style="width: 100%"
                          class="position-relative"
                        >
                          <i
                            v-if="confirmPasswordType == 'password'"
                            class="fa fa-eye position-absolute togglePassword text-secondary"
                            @click="toggleShowConfirmPassword"
                            v-tooltip.top-center="'Show Password'"
                            aria-hidden="true"
                          ></i>
                          <i
                            v-if="confirmPasswordType == 'text'"
                            class="fa fa-eye-slash position-absolute togglePassword text-secondary"
                            @click="toggleShowConfirmPassword"
                            v-tooltip.top-center="'Hide Password'"
                            aria-hidden="true"
                          ></i>
                          <el-input
                            :type="confirmPasswordType"
                            v-model="resetPassword.confirmPassword"
                            placeholder="Please enter Password"
                            style="width: 100%"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <div
                    style="width: 100%"
                    class="mb-3 mt-3 d-flex justify-content-end"
                  >
                    <l-button
                      @click="submit"
                      type="info"
                      class="pl-5 pr-5 d-block"
                    >
                      Submit
                    </l-button>
                  </div>
                </el-form>
              </el-card>
            </el-tab-pane>
            <!-- Billing Info Form  -->
            <!-- Only show this tab if user is a stundent or an inndividual -->
            <el-tab-pane
              name="billingInfo"
              label="Billing Info"
              v-if="this.showBillingInfo"
            >
              <span slot="label"
                ><i class="el-icon-money"></i> Billing Info</span
              >
              {{ this.user.type }}
              <el-alert show-icon class="mb-3"
                >By adding a credit card you are subscribing to
                PersonalLearningCoach.com and will be billed monthly.
              </el-alert>
              <el-form
                ref="processPaymentFormRef"
                :model="credit"
                :rules="paymentRules"
                label-position="top"
              >
                <el-row :gutter="20">
                  <!-- Crad Name  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Card Name"
                      label-width="30%"
                      prop="card_name"
                    >
                      <el-input
                        v-model="credit.card_name"
                        class="purple-input"
                        placeholder="Card Name"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Card Number  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Card Number"
                      label-width="30%"
                      prop="card_number"
                    >
                      <el-input
                        v-model="credit.card_number"
                        class="purple-input"
                        placeholder="Card Number"
                        maxlength="16"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Expiration Date  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Expiration date"
                      label-width="30%"
                      prop="exp_time"
                    >
                      <el-date-picker
                        v-model="credit.exp_time"
                        type="month"
                        placeholder="Expiration date"
                        value-format="yyyy-MM-dd"
                        :picker-options="datePickerOptions"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <!-- CVC  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item label="CVC" label-width="30%" prop="cvc_code">
                      <el-input
                        v-model="credit.cvc_code"
                        class="purple-input"
                        placeholder="CVC"
                        maxlength="3"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- BilligZip Code  -->
                  <!-- <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Billing  Zip Code"
                      label-width="30%"
                      prop="zip_code"
                    >
                      <el-input
                        v-model="user.zip_code"
                        class="purple-input"
                        placeholder="Billing  Zip Code"
                      />
                    </el-form-item>
                  </el-col> -->
                  <!-- <el-col :sm="12" class="my-2">
                    <el-form-item label="Period" label-width="30%" prop="period">
                    <el-input
                      v-model="user.period"
                      class="purple-input"
                      placeholder="Period"
                    />
                  </el-form-item>
                  </el-col> -->

                  <!-- Auto renew check  -->
                  <el-col
                    :sm="12"
                    class="d-flex justify-content-center align-items-center h-100"
                  >
                    <el-checkbox
                      v-model="user.renew"
                      label="Auto Renew"
                      color="green"
                      :checked="autoRenewCheck"
                    ></el-checkbox>
                  </el-col>
                  <el-col :cols="24" class="text-right">
                    <el-button
                      @click="processPayment"
                      type="success"
                      class="mr-0"
                    >
                      Process Payment
                    </el-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import Swal from "sweetalert2";
import { Auth } from "../../utils/auth";

export default {
  name: "edit",
  data() {
    return {
      menu: false,
      minDate: new Date(Date.now()).toISOString().substr(0, 10),
      isInstitutionInfoLoading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        },
      },
      personalInfoRules: {
        first_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        zip_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
      },
      googleClassRules: {
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
      },
      syncForm: { email: "" },
      dataSyncedWithClass: false,
      classroomLoading: false,
      paymentRules: {
        card_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        card_number: [
          {
            min: 16,
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        exp_time: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
        cvc_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
            min: 3,
          },
        ],
      },
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password_confirmation: null,
        password: null,
        age_group_id: null,
        language: null,
        gender: null,
        type: null,
        state: null,
        country: null,
        city: null,
        student_classroom_id: null,
        zip_code: null,
        renew: false,
        // zip: null,
      },
      credit: {
        card_name: null,
        card_number: null,

        exp_time: null,
        cvc_code: null,
      },
      email: {
        confirmemail: null,
      },
      ageList: [
        { value: 1, label: "6-12 years old" },
        { value: 2, label: "13 -21 years old" },
        { value: 3, label: "22 or older" },
        { value: 4, label: "ABE" },
      ],

      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia ",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory ",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands ",
        "Central African Republic ",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands ",
        "Colombia",
        "Comoros ",
        "Congo ",
        "Congo ",
        "Cook Islands ",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic ",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands  [Malvinas]",
        "Faroe Islands ",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories ",
        "Gabon",
        "Gambia ",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands ",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands ",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger ",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands ",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands ",
        "United States of America ",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands",
      ],
      show_institution_promo_dialog: false,
      promoForm: {
        new_institution_promo: {
          promo_code: null,
        },
      },
      promoRules: {
        new_institution_promo: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
          },
        ],
      },
      joinFullORgError: "",
      isResetPasswordLoading: false,
      passwordType: "password",
      confirmPasswordType: "password",
      resetPassword: {},
      resetPassRules: {
        password: [
          {
            required: true,
            message: "Please input password",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please reenter password",
            trigger: "blur",
          },
        ],
      },
      // If user clicked Sync data from profile page: open edit profile with google classrooms tab active
      // else open personal Info tab
      activeTab:
        this.$route.path == "/sync-data" ? "googleClass" : "personalInfo",
    };
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    // auto renew auto checked for US users
    autoRenewCheck() {
      return this.user.country === "United States";
    },
    showBillingInfo() {
      return (
        this.user.type != "Institution" &&
        this.user.type != "institution" &&
        !this.$route.params.id
      );
    },
  },
  mounted() {
    this.$store.dispatch("user/user", this.id).then((_) => {
      this.user = this.$store.state.user.alluserData;
      this.org = this.$store.getters["user/orgs"];
      this.syncForm.email = this.user.student_classroom_id;
    });
    // get languages available languages for user language set
    this.$store.dispatch("language/getLanguages");
    // set dataSyncedWithClass variable if users data is synced with A classroom
    this.dataSyncedWithClass = this.user.student_classroom_id ? true : false;
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("user/users", { query: query });
      this.$store.dispatch("topics/GetTopics", { query: null });
    },
    save() {
      this.$refs["personalInfoFormRef"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/save", { id: `/${this.id}`, query: this.user })
            .then((_) => {
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              this.$router.go(-1);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    leaveOrganization() {
      // set laoding to true to shpw loading while making request
      this.isInstitutionInfoLoading = true;

      this.$store
        .dispatch("user/leaveOrganization", { id: this.id })
        .then((_) => {
          this.$store.dispatch("user/user", this.id).then((_) => {
            // refresh after leaving institution
            this.user = this.$store.state.user.alluserData;
            this.org = this.$store.getters["user/orgs"];
            // remove loading
            this.isInstitutionInfoLoading = false;
          });
        });
    },

    submitPromoCode() {
      this.$refs["promoRef"].validate((valid) => {
        if (valid) {
          this.isInstitutionInfoLoading = true;
          this.$store
            .dispatch("user/joinOrganization", {
              promoCode: this.promoForm.new_institution_promo,
              id: this.id,
            })
            .then((_) => {
              //  If Organiations is full, server will retur an error messgae with the response
              this.joinFullORgError =
                this.$store.getters["user/join_full_org_error"];
              // if organization is full
              if (this.joinFullORgError.length > 0) {
                Swal.fire({
                  title: "Error!",
                  text: this.joinFullORgError,
                  icon: "error",
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                }).then(() => {
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              } else {
                // if organization is not full
                // update data in panels
                this.$store.dispatch("user/user", this.user.id).then(() => {
                  // refresh after Joining institution
                  this.user = this.$store.state.user.alluserData;
                  this.org = this.$store.getters["user/orgs"];
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // update table data
                  this.Refresh({});
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              }
            });
        } else {
          console.log("erorr");
          return false;
        }
      });
    },
    processPayment() {
      // console.log(this.credit);
      this.$refs["processPaymentFormRef"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/processPayment", {
              id: `${this.id}`,
              query: this.credit,
            })
            .then((_) => {
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              this.$router.go(-1);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // reste password methods
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleShowConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType == "password" ? "text" : "password";
    },
    // reset password
    submit() {
      this.$refs["resetPassRef"].validate((valid) => {
        if (valid) {
          this.isResetPasswordLoading = true;
          //  If passwords does not match
          if (
            this.resetPassword.password !== this.resetPassword.confirmPassword
          ) {
            Swal.fire({
              icon: "error",
              text: "Passwords does not match",
              confirmButtonClass: "btn btn-info btn-fill",
              showConfirmButton: false,
              showCloseButton: true,
            });
          } else {
            // call reset password on store
            this.$store
              .dispatch("user/changePasswordByAdmin", {
                id: this.user.id,
                newPasswordData: {
                  password: this.resetPassword.password,
                  confirm_password: this.resetPassword.confirmPassword,
                },
              })
              .then(() => {
                Swal.fire({
                  icon: "success",
                  text: "Password has been changed",
                  confirmButtonClass: "btn btn-info btn-fill",
                  showConfirmButton: false,
                  showCloseButton: true,
                });
                this.isResetPasswordLoading = false;
              });

            // console.log(this.user.id);
            // console.log("password reset ");
            // console.log("password  ", this.resetPassword.password);
            // console.log("password  ", this.resetPassword.confirmPassword);
          }
        } else {
          console.log("error submitting");
          return false;
        }
      });
    },

    // join class room
    syncClass() {
      this.classroomLoading = true;
      this.$refs["googleClassFormRef"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/syncClassRoom", {
              id: `${this.id}`,
              email: { email: `${this.syncForm.email}` },
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Your account synced successfully",
                showConfirmButton: false,
                showCloseButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push("/profile");
                this.classroomLoading = false;
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    unsyncClass() {
      Swal.fire({
        title: "Are you sure?",
        text: `are you sure to unsync your data?`,

        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Confirm",
        buttonsStyling: false,
      }).then((data) => {
        if (data.isConfirmed) {
          this.classroomLoading = true;
          this.$store
            .dispatch("user/unsyncClassRoom", {
              id: this.id,
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Your account unsynced successfully",
                showConfirmButton: false,
                showCloseButton: false,
                timer: 2000,
              }).then(() => {
                this.$router.push("/profile");
                this.classroomLoading = false;
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.container--fluid {
  min-height: 80vh !important;
}
.togglePassword {
  z-index: 5;
  right: 12px;
  bottom: 5%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}
</style>
